import React, { useMemo } from "react";
import styled from "styled-components";

import media from "@styles/media";
import colors from "@styles/colors";
import text from "@styles/text";

import { ReactComponent as LinkedinSVG } from "@svg/linkedIn.svg";

type props = {
  directors: any;
};

const Directors: React.FC<props> = ({ directors }) => {
  
  const sortedDirectors = useMemo(() => directors.sort((a, b) => {
    if (a.order > b.order) {
      return 1;
    } else if (a.order < b.order) {
      return -1;
    } else {
      return 0;
    }
  }), [directors])

  const cards = useMemo(() => sortedDirectors.map((item, index) => {
    return (
      <Card className="director" key={index}>
        <Name>{item.fullName}</Name>
        <P>{item.bio.bio}</P>
        {item.linkedin && (
          <a href={item.linkedin} target="_blank no-referer">
            <Linkedin />
          </a>
        )}
      </Card>
    );
  }), [sortedDirectors])

  return (
    <Wrapper>
      <TopSection>
        <Title>Board of directors</Title>
        <HR />
      </TopSection>
      <CardContainer>{cards}</CardContainer>
    </Wrapper>
  );
};

export default Directors;

const Wrapper = styled.section`
  background-color: ${colors.black};
  border-left-style: solid;
  border-color: ${colors.keppel100};

  ${media.fullWidth} {
    border-width: 2.083vw;
    padding-top: 4.583vw;
    padding-left: 9.236vw;
    padding-right: 11.319vw;
    padding-bottom: 8.681vw;
  }

  ${media.desktop} {
    border-width: 2.083vw;
    padding-top: 4.583vw;
    padding-left: 9.236vw;
    padding-right: 11.319vw;
    padding-bottom: 8.681vw;
  }

  ${media.tablet} {
    border-width: 3.593vw;
    padding-top: 5.15vw;
    padding-left: 2.395vw;
    padding-right: 5.988vw;
    padding-bottom: 14.731vw;
  }

  ${media.mobile} {
    border-width: 1.333vw;
    padding-top: 13.067vw;
    padding-left: 5.333vw;
    padding-bottom: 26.133vw;
  }
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.fullWidth} {
    margin-bottom: 7.708vw;
  }

  ${media.desktop} {
    margin-bottom: 7.708vw;
  }

  ${media.tablet} {
    margin-bottom: 8.503vw;
  }

  ${media.mobile} {
    margin-bottom: 22.667vw;
  }
`;

const Title = styled.h2`
  color: ${colors.culturedWhite60};

  ${media.fullWidth} {
    ${text.desktopLHeading}
  }

  ${media.desktop} {
    ${text.desktopLHeading}
  }

  ${media.tablet} {
    ${text.tabletMHeading}
  }

  ${media.mobile} {
    ${text.mobileSHeading}
  }
`;

const HR = styled.hr`
  background-color: ${colors.culturedWhite60};
  border: none;
  height: 1px;
  margin: 0px;

  ${media.fullWidth} {
    width: 36.597vw;
  }

  ${media.desktop} {
    width: 36.597vw;
  }

  ${media.tablet} {
    width: 28.144vw;
  }

  ${media.mobile} {
    display: none;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${media.fullWidth} {
    padding-left: 7.847vw;
  }

  ${media.desktop} {
    padding-left: 7.847vw;
  }

  ${media.tablet} {
    padding-left: 7.425vw;
  }

  ${media.mobile} {
    flex-direction: column;
    padding-left: 23.467vw;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${media.fullWidth} {
    width: 20.347vw;
    margin-right: 3.125vw;
    margin-bottom: 4.861vw;

    :nth-of-type(3n) {
      margin-right: 0px;
    }
  }

  ${media.desktop} {
    width: 20.347vw;
    margin-right: 3.125vw;
    margin-bottom: 4.861vw;

    :nth-of-type(3n) {
      margin-right: 0px;
    }
  }

  ${media.tablet} {
    width: 35.09vw;
    margin-right: 10.18vw;
    margin-bottom: 11.976vw;

    :nth-of-type(2n) {
      margin-right: 0px;
    }
  }

  ${media.mobile} {
    width: 63.733vw;
    margin-bottom: 16vw;
  }
`;

const Name = styled.h3`
  color: ${colors.culturedWhite60};

  ${media.fullWidth} {
    ${text.desktopXSHeading}
    margin-bottom: 1.389vw;
  }

  ${media.desktop} {
    ${text.desktopXSHeading}
    margin-bottom: 1.389vw;
  }

  ${media.tablet} {
    ${text.tabletXSHeading}
    margin-bottom: 2.395vw;
  }

  ${media.mobile} {
    ${text.mobileXXSHeading}
    margin-bottom: 5.333vw;
  }
`;

const P = styled.p`
  color: ${colors.culturedWhite60};
  font-weight: normal;

  ${media.fullWidth} {
    ${text.desktopSmallBody}
    margin-bottom: 1.389vw;
  }

  ${media.desktop} {
    ${text.desktopSmallBody}
    margin-bottom: 1.389vw;
  }

  ${media.tablet} {
    ${text.tabletSmallBody}
    margin-bottom: 2.395vw;
  }

  ${media.mobile} {
    ${text.mobileSmallBody}
    margin-bottom: 5.333vw;
  }
`;

const Linkedin = styled(LinkedinSVG)`
  height: auto;

  ${media.fullWidth} {
    width: 1.667vw;
  }

  ${media.desktop} {
    width: 1.667vw;
  }

  ${media.tablet} {
    width: 2.874vw;
  }

  ${media.mobile} {
    width: 6.4vw;
  }
`;
